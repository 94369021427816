// Common functions for Walt Disney World and Disneyland Paris searches; v2.0.0
// Author: Tom Nelson, December 2015

// -------------------------------------------------------------------------------------------------------------------

// Quick quote panel; homepage only

// Reset all option when #ages panel is closed
function reset() {
    $('.ages > span').addClass('hide').hide(); $('#ages select').attr('disabled', 'disabled');
}

// Control display of child age select menus
// Updated to manage behaviour by framework (desktop/mobile)
function children() {
    // Count child pax
    var childpax = $('#children').val();

    // Drop menu updated to show that at least one child is travelling
    if (childpax > 0) {
        // Different behaviours for desktop/mobile
        if (desktop) {
            $('#ages').slideDown(function() {
                $(this).removeClass('hide');
            });
        } else if (mobile) {
            $('#ages').removeClass('hide');
        }

        var transitionTime = $('#ages').is(':hidden') ? 0 : 500;

        // Loop through spans and fade in/out
        $('.ages > span').each(function(index) {
            if (index < childpax) {
                $(this).find('select').removeAttr('disabled');
                $(this).fadeIn(transitionTime, function() {
                    $(this).removeClass('hide');
                });
            } else {
                $(this).fadeOut(transitionTime, function() {
                    $(this).find('select').attr('disabled','disabled');
                    $(this).addClass('hide');
                });
            }
        });
    } else {
        if (desktop) {
            $('#ages').slideUp(function() {
                $(this).addClass('hide'); reset();
            })
        } else if (mobile) {
            $('#ages').addClass('hide'); reset();
        }
    }
    if ($('#ages').attr('data-age-validation') == 'error') {
        if (mobile) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#ages").offset().top
            }, 500);
        }
    }
}

// Hide and show menus based on holiday type
function holiday() {
// Check holiday type
    var type = $('input:radio:checked').val().toLowerCase();
// Show relevant menus for transport option, and update button label
    transport(type); button(type);
}

// Set menus for transport option
function transport(type) {
    var visible = $('div.search > .options:visible').attr('id'); // Hide currently visible, unless type has not changed
// Initially all options on the mobile QQ are visually hidden
    if (!visible && mobile) { var visible = $('div.search > .options:not(.hide)').attr('id'); }
// Fade out/in correct panel (desktop)
    var hide = $('#' + visible)
    var show = $('#' + type + '-options')
// Don't run until page has rendered
    if (visible.indexOf(type) != 0) {
// Desktop
        if (desktop) { hide.fadeOut(function() { hide.addClass('hide'); show.fadeIn(function() { show.removeClass('hide'); }); }); }
// Mobile
        if (mobile) {
            hide.addClass('hide');
            show.removeClass('hide');
// WDW only
            if (brand == 'wdw') { accessible(type); }
        }
    }
}

// Set #day drop menu based on month/year selected
function days() {
// Built array and load days in the month
    var monthlength = new Array(31,28,31,30,31,30,31,31,30,31,30,31);
// Get data
    var monthyear = $('#month').val();
    var year = parseInt(monthyear.split('^')[1]);
    var month = parseInt(monthyear.split('^')[0]);
// Correct for leap year
    if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) { monthlength[1] = 29; }
// Update #day menu
    selectmenu(monthlength[month - 1]);
    calendar();
}

// Set length of numeric select menu
function selectmenu(length) {
    var object = $('#day')
    var option = $('#day option')
// Read last option value on menu
    var size = parseInt($('#day option:last').val());
// Set selected option to new length
    if (length < object.val()) { object.val(length).attr('selected',true); }
// Add days
    if (size < length) { for (var i = size + 1; i <= length; i++) { $('<option/>').val(i).text(i).appendTo('#day'); } }
// Remove days
    else if (size > length) { for (var i = size; i > length; i--) { $('#day option[value=' + i + ']').remove(); } }

}

// When date menus are changed update the corresponding calendar hidden field
function calendar() {
// Get values
    var monthyear = $('#month').val();
    var year = parseInt(monthyear.split('^')[1]);
    var month = parseInt(monthyear.split('^')[0]);
    var day = parseInt($('#day').val());
// Set date
    var date = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
// Update hidden text field
    $('#date').val(date).trigger('change');
}

// When the date is selected via the calendar update the menus
function date(day,month,year) {
// Set values
    $('#month').val((month + 1) + '^' + year) // Months are zero-indexed in JavaScript
    days(); // Update days menu to show correct number of days for month
    $('#day').val(day) // Set day
}

// Capture search data and log in Google Analytics
function searches(type,query,brand,data) {
// Set up variables
    var date = null;
    var nights = null;
    var adults = null;
    var children = null;
    var ages = null;
    var accessible = null;
    var departure = null;
    var arrival = null;
    var cabin = null;
    var category = null;
    var station = null;
    var departing = null;
    var returning = null;
    var vehicle = null;
// Do we have data to load into the function?
    if (data) {
        var array = data.split('|');
// Mandatory variables
        date = array[0];
        nights = array[1];
        adults = array[2];
        children = array[3];
    }
// -------------------------------------------
// Mandatory variables
// -------------------------------------------
// If we don't have data then detect from on-page elements
    if (!data) {
        var monthyear = $('#month').val();
        var year = parseInt(monthyear.split('^')[1]);
        var month = parseInt(monthyear.split('^')[0]) - 1; // Months are zero-indexed in JavaScript
        var day = parseInt($('#day').val());
        var outbound = new Date(year,month,day);
        date = moment(outbound).format('YYYYMMDD'); // Date; match format to native GA format (YYYYMMDD)
        nights = $('#nights').val(); // Nights
        adults = $('#adults').val(); // Adults
        children = $('#children').val(); // Children
    }
// Fire events
    GA.sendEvent('Search','Holiday',query); // Holiday type; duplicate of Book > Search event label
    GA.sendEvent('Search','Date',date);
    GA.sendEvent('Search','Nights',nights);
    GA.sendEvent('Search','Adults',adults);
    GA.sendEvent('Search','Children',children);
// -------------------------------------------
// Optional variables
// -------------------------------------------
// Ages
    if (parseInt(children) > 0) {
        if (data) { ages = array[4]; } // From data
        else { ages = $('span:visible select[name="ages"]').map(function () { return $(this).val(); }).get(); } // From on-page
// Fire event
        GA.sendEvent('Search','Ages','' + ages + ''); // Ages can be a comma-separated string so escape
    }
// -------------------------------------------
// Walt Disney World variables
// -------------------------------------------
    if (brand == 'wdw') {
// Do we have data to load into the function?
        if (data) {
            if (array[5]) { accessible = true } // Accessible rooms
// Other data
            switch (type) {
                case 'flights':
                    departure = array[6];
                    arrival = array[7];
                    cabin = array[8];
                    break;
                case 'package':
                case 'hotel':
                    category = 'All Hotels & Villas' // Searches via WDPRO always search for 'All'
                    break;
            }
        } else {
// If we don't have data then detect from on-page elements
            if ($('#accessible').is(':checked')) { accessible = true } // Accessible rooms
// Other data
            switch (type) {
                case 'flights':
                    departure = $('#flights-from option:selected').text();
                    arrival = $('#flights-to option:selected').text();
                    cabin = $('#flights-cabin option:selected').text();
                    break;
                case 'package':
                    category = $('#package-category option:selected').text();
                    break;
                case 'hotel':
                    category = $('#hotel-category option:selected').text();
                    break;
            }
        }
// Fire events
        if (accessible) { GA.sendEvent('Search','Accessible','Accessible Rooms'); }
        if (departure) { GA.sendEvent('Search','Departure Airport',departure); }
        if (arrival) { GA.sendEvent('Search','Arrival Airport',arrival); }
        if (cabin) { GA.sendEvent('Search','Cabin Class',cabin); }
        if (category) { GA.sendEvent('Search','Hotel Category',category); }
    }
// -------------------------------------------
// Disneyland Paris variables
// -------------------------------------------
    if (brand == 'dlp') {
// Detect from on-page elements
        switch (type) {
            case 'eurostar':
                station = $('#eurostar-station option:selected').text();
                departing = $('#eurostar-departure option:selected').text();
                returning = $('#eurostar-return option:selected').text();
                break;
            case 'flights':
                departure = $('#flights-airport option:selected').text();
                break;
            case 'selfdrive':
                vehicle = $('#selfdrive-vehicle option:selected').text();
                departing = $('#selfdrive-departure option:selected').text();
                returning = $('#selfdrive-return option:selected').text();
                break;
        }
// Fire events
        if (station) { GA.sendEvent('Search','Departure Station',station); }
        if (departing) { GA.sendEvent('Search','Departure Time',departing); }
        if (returning) { GA.sendEvent('Search','Return Time',returning); }
        if (departure) { GA.sendEvent('Search','Departure Airport',departure); }
        if (vehicle) { GA.sendEvent('Search','Vehicle',vehicle); }
    }
}

// -------------------------------------------------------------------------------------------------------------------

// Mobile quick quote
function quickquote(brand) {
// Update pax
    var adult = $('#adults').val();
    var child = $('#children').val();
// Determine plurals
    var adults = 'Adults'; if (parseInt(adult) == 1) { adults = 'Adult' };
    var children = 'Children'; if (parseInt(child) == 1) { children = 'Child' };
// Update pax legend
    $('fieldset.passengers legend span').text('(' + adult + ' ' + adults + ' - ' + child + ' ' + children + ')');
// -------------------------------------------
// Walt Disney World variables
// -------------------------------------------
    if (brand == 'wdw') {
// Update airports
        var from = $('#flights-from').val();
        var to = $('#flights-to').val();
// Update flights legend
        $('#flights-options legend span').text('(' + from + ' - ' + to + ')');
// -------------------------------------------
// Update packages
        var packages = $('#package-category').val();
// Update flights legend
        $('#package-options legend span').text('(' + category(packages) + ')');
// -------------------------------------------
// Update hotels
        var hotels = $('#hotel-category').val();
// Update flights legend
        $('#hotel-options legend span').text('(' + category(hotels) + ')');
    }
// -------------------------------------------
// Disneyland Paris variables
// -------------------------------------------
    if (brand == 'dlp') {
// Update stations
        var from = $('#eurostar-station').val();
// Update Eurostar legend
        $('#eurostar-options legend span').text('(' + from + ' - MLV)');
// -------------------------------------------
// Update airports
        var from = $('#flights-airport').val();
// Update flights legend
        $('#flights-options legend span').text('(' + from + ' - PAR)');
// -------------------------------------------
// Update vehicle
        var vehicle = $('#selfdrive-vehicle').val();
        var height;
        switch (vehicle) {
            case 'Car':
                height = '<';
                break;
            case 'HighCar':
                height = '>'
                break;
        }
// Update self drive legend
        $('#selfdrive-options legend span').text('(Car ' + height + ' 1.8m)');
    }
}

// -------------------------------------------------------------------------------------------------------------------

function dateIsExcluded(date) {
    var isExcluded = false;
    var momentDate = moment(date);
    exclusionWindows.forEach( function(window) {
        isExcluded = isExcluded || momentDate.isBetween(window.start, window.end, undefined, '[]');
    });
    return isExcluded;
}

// Read querystring
function getQueryStringValue (key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function() {

// Quick quote functions
    if ($('#quick-quote').length) {

// Datepicker (desktop only)
        if (desktop) {
// Set datepicker defaults
            $.datepicker.setDefaults({
                dateFormat:'dd-mm-yy',
                showOn:'button',
                buttonText:'',
                firstDay:1,
                prevText:'',
                nextText:'',
                showAnim:'',
                onClose:function(inst) { calendar(inst.id); $('div.calendar').addClass('shadow'); },
                onSelect:function(dateText,inst) { date(inst.selectedDay,inst.selectedMonth,inst.selectedYear); },
                beforeShow:function() { $('div.calendar').removeClass('shadow'); },
                beforeShowDay: function (date) {
                    return [!dateIsExcluded(date)];
                },
            });
// Fix position of datepicker
            $.extend($.datepicker,{_checkOffset:function(inst,offset,isFixed){return offset}});
// Initialise calendar; dates are formatted YYYY,MM,DD with the month zero-indexed
            $('#date').datepicker({ minDate:mindate,maxDate:maxdate });
        };

// Fix child age menus for refresh on Firefox
        children();

// Setup menus
        days(); holiday();

// Search form
        $('fieldset :radio').click(function() { holiday(); });
        $('#children').change(function() { children(); });
        $('#month').change(function() { days(); });
        $('#day').change(function() { calendar(); }); // Update calendar

// Mobile quick quote
        if (mobile) {
// Fire on load and/or 'back' in browser
            quickquote(brand); $(window).bind('pageshow',function() { quickquote(brand); });
// Scroll when opening quick quote for the first time, then pause billboard; keep 40px of billboard visible for context
            $('#quick-quote .holiday legend').one('click',function() { jQuery.scrollTo($(this),600,{offset:-60,axis:'y'}); });
// Manage quick quote panel open/close states
            $('#quick-quote .holiday legend').click(function() { $(this).add('#quick-quote').toggleClass('open'); });
            $('#quick-quote fieldset:not(.holiday) legend').click(function() { $(this).closest('fieldset').toggleClass('open'); });
            $('#quick-quote .passengers select, #quick-quote .search select').change(function() { quickquote(brand); });
        }

// Scroll to search panel
        if (mobile) {
// Search in querystring
            var querystring = getQueryStringValue('search')
            if (querystring == 'true') { setTimeout(function() { jQuery.scrollTo($('#quick-quote'),600,{offset:-60,axis:'y'}); },2000); }
        }

    }


    $('select[name="ages"]').change(function () {
        if (parseInt($(this).val()) === 0) {
            $(this).parent().siblings('label').addClass('error');
        } else {
            $(this).parent().siblings('label').removeClass('error');
        }
    });

    $('#form').submit( function(e) {
        var childpax = $('#children').val();
        var agesArray = $('#form [name=ages]').serializeArray();
        if (parseInt(childpax) !== agesArray.length ) {
            //This should never happen
            children();
        }

        var anyIsEmpty = false;

        $('.ages > span').each(function (index) {
            if (index < childpax) {
                if (parseInt($(this).find('select').val()) === 0) {
                    $(this).find('label').addClass('error');
                    anyIsEmpty = true;
                }
            }
        });

        if (anyIsEmpty) {
            if (mobile) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#ages").offset().top
                }, 500);
            }
            e.stopImmediatePropagation();
            e.preventDefault();
        }
    });
});

$(window).on('load', function () {
    children();
});

